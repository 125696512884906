import React, { useRef } from 'react';
import { useFormik } from 'formik';
import SignatureCanvas from 'react-signature-canvas';
import Modal from 'components/newComponents/Modal';
import { ModalButton } from 'components/newComponents/Buttons';
import Radio from 'components/newComponents/RadioButton';
import { options } from './data';
import Api from './api';
import './index.scss';

export const TermsOfPaymentSign = ({ jobId, isOpen, onClose }) => {
  const canvas = useRef(null);

  const submit = async () => {
    const sign = canvas.current?.toDataURL('base64');

    if (!canvas.current.isEmpty()) {
      const response = await Api.sign(jobId, sign, 'ld_contract_terms_of_payment');

      if (response) {
        window.location.reload();
      }
    }
  };

  return (
    <Modal style={{ width: 670, maxWidth: 670 }} header="Terms Of Payment" show={isOpen} onHide={onClose}>
      <div className="ld-contract-invoice-modal-body">
        <p style={{ color: 'gray' }}>Sign Here</p>
        <SignatureCanvas ref={canvas} canvasProps={{ className: 'sign-canvas' }} />
      </div>
      <div className="footer-buttons">
        <ModalButton title="Cancel" variant="cancel" onClick={onClose} />
        <ModalButton title="Sign" variant="confirm" type="submit" onClick={submit} />
      </div>
    </Modal>
  );
};

export const AcknowledgeTermsAndConditionsSign = ({ jobId, isOpen, onClose }) => {
  const canvas = useRef(null);

  const submit = async () => {
    const sign = canvas.current?.toDataURL('base64');

    if (!canvas.current.isEmpty()) {
      const response = await Api.sign(jobId, sign, 'ld_contract_acknowledge_client');

      if (response) {
        window.location.reload();
      }
    }
  };

  return (
    <Modal style={{ width: 670, maxWidth: 670 }} header="Acknowledge of terms and conditions" show={isOpen} onHide={onClose}>
      <div className="ld-contract-invoice-modal-body">
        <p style={{ color: 'gray' }}>Sign Here</p>
        <SignatureCanvas ref={canvas} canvasProps={{ className: 'sign-canvas' }} />
      </div>
      <div className="footer-buttons">
        <ModalButton title="Cancel" variant="cancel" onClick={onClose} />
        <ModalButton title="Sign" variant="confirm" type="submit" onClick={submit} />
      </div>
    </Modal>
  );
};

export const InsuranceOptions = ({ job_id, isOpen, onClose }) => {
  const { setFieldValue, values } = useFormik({
    initialValues: { job_id, insurance_option: 0 },
  });

  const submit = async (values) => {
    const response = await Api.insurance(values.job_id, values.insurance_option);

    if (response.data) {
      window.location.reload();
    }
  };

  return (
    <Modal style={{ width: 670, maxWidth: 670 }} header="Carrier's Liability Options" show={isOpen} onHide={onClose}>
      <div className="ld-contract-invoice-modal-body">
        {options.map((option) => (
          <Radio
            key={option.value}
            value={option.value}
            title={option.label}
            current={values.insurance_option}
            radioStyles={{ marginRight: 20, marginLeft: 0 }}
            titleStyles={{ fontWeight: 500, color: values.insurance_option === option.value ? 'black' : '#b1c3cc' }}
            onChange={() => setFieldValue('insurance_option', option.value)}
          />
        ))}
      </div>

      <div className="footer-buttons">
        <ModalButton title="Cancel" variant="cancel" onClick={onClose} />
        <ModalButton title="Submit" disabled={values.insurance_option === 0} variant="confirm" onClick={() => submit(values)} />
      </div>
    </Modal>
  );
};

export const CustomerSing = ({ jobId, isOpen, onClose }) => {
  const canvas = useRef(null);

  const submit = async () => {
    const sign = canvas.current.toDataURL('base64');
    const documentName = 'ld_contract_interstate_bill_client';

    if (!canvas.current.isEmpty()) {
      const response = await Api.sign(jobId, sign, documentName);

      if (response) {
        window.location.reload();
      }
    }
  };

  return (
    <Modal style={{ width: 670, maxWidth: 670 }} header="Customer's Signature" show={isOpen} onHide={onClose}>
      <div className="ld-contract-invoice-modal-body">
        <p style={{ color: 'gray' }}>Sign Here</p>
        <SignatureCanvas ref={canvas} canvasProps={{ className: 'sign-canvas' }} />
      </div>

      <div className="footer-buttons">
        <ModalButton title="Cancel" variant="cancel" onClick={onClose} />
        <ModalButton title="Sign" variant="confirm" type="submit" onClick={submit} />
      </div>
    </Modal>
  );
};

export const CustomerInitials = ({ jobId, isOpen, onClose }) => {
  const canvas = useRef(null);

  const submit = async () => {
    const sign = canvas.current.toDataURL('base64');
    const documentName = 'ld_contract_interstate_bill_client_initials';

    if (!canvas.current.isEmpty()) {
      const response = await Api.sign(jobId, sign, documentName);

      if (response) {
        window.location.reload();
      }
    }
  };

  return (
    <Modal style={{ width: 670, maxWidth: 670 }} header="Customer's Initials" show={isOpen} onHide={onClose}>
      <div className="ld-contract-invoice-modal-body">
        <p style={{ color: 'gray' }}>Sign Here</p>
        <SignatureCanvas ref={canvas} canvasProps={{ className: 'sign-canvas' }} />
      </div>

      <div className="footer-buttons">
        <ModalButton title="Cancel" variant="cancel" onClick={onClose} />
        <ModalButton title="Sign" variant="confirm" type="submit" onClick={submit} />
      </div>
    </Modal>
  );
};
