/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { useFormik } from 'formik';
import Modal from 'components/newComponents/Modal';
import { ModalButton } from 'components/newComponents/Buttons';
import CheckBox from 'components/newComponents/CheckBox';

import './index.scss';

export const Sign = ({ data, document, signCanvas, isOpen, onClose, onSubmit }) => {
  return (
    <Modal style={{ width: window.innerWidth - 60, maxWidth: 670 }} header="Sign Estimate" show={isOpen} onHide={onClose}>
      {data && document && (
        <div className="invoice-modal-description">
          {document === 'quote_ld' ? (
            <React.Fragment>
              <div>
                <b>From: </b>
                {data.moving_from.full_address}
              </div>
              <div>
                <b>To: </b>
                {data.moving_to.full_address}
              </div>
              <div>
                <b>Pickup: </b>${data.ld_calc.pickup_total}
              </div>

              <div>
                <div>
                  <b>Storage:</b>
                </div>
                <ul>
                  <li style={{ listStyleType: 'disk', listStyle: 'inside', paddingLeft: 8 }}>
                    <b>Period of storage </b>
                    {Number(data.storage_calc.days_length)} days
                  </li>
                  <li style={{ listStyleType: 'disk', listStyle: 'inside', paddingLeft: 8 }}>
                    <b>First month free</b>
                  </li>
                  {Number(data.storage_calc.month_length) > 6 && (
                    <React.Fragment>
                      <li style={{ listStyleType: 'disk', listStyle: 'inside', paddingLeft: 8 }}>
                        <b>From second month: </b> ${(+data.storage_calc.balance / Math.ceil(data.storage_calc.month_length) - 1).toFixed(2)} per
                        month
                      </li>
                    </React.Fragment>
                  )}
                  <li style={{ listStyleType: 'disk', listStyle: 'inside', paddingLeft: 8 }}>
                    <b>Total: </b>${data.storage_calc.balance}
                  </li>
                </ul>
              </div>
              <div>
                <b>Delivery: </b>${data.ld_calc.delivery_total}
              </div>
              <div>
                <b>Total CF: </b>
                {data.ld_calc.total_cf}
              </div>
              <div>
                <b>Final Total: </b>$
                {+data.ld_calc.base_price +
                  +data.ld_calc.additional_price +
                  +data.job_other_services.origin_services_total +
                  +data.job_other_services.destination_services_total}
              </div>
            </React.Fragment>
          ) : (
            // document === 'invoice'
            <React.Fragment>
              <p>
                Minimum Estimated move time for {data.move_type_name} - {data.hours} hours X ${Number(data.rate).toFixed(2)} - $
                {(data.hours * data.rate).toFixed(2)}
              </p>
              <p>
                Not a Final Total. Additional hour, if needed, is ${Number(data.rate).toFixed(2)}, billed every 5 minutes @$
                {(Number(data.rate) / 12).toFixed(2)}
              </p>
            </React.Fragment>
          )}
        </div>
      )}
      <div className="invoice-modal-body">
        <p style={{ color: 'gray' }}>Sign Here</p>
        <SignatureCanvas ref={signCanvas} canvasProps={{ className: 'sign-canvas' }} />
      </div>
      <div className="footer-buttons">
        <ModalButton title="Cancel" variant="cancel" onClick={onClose} />
        <ModalButton title="Sign" variant="confirm" type="submit" onClick={onSubmit} />
      </div>
    </Modal>
  );
};

export const ImportantTerms = ({ data, isOpen, onClose, onSubmit }) => {
  const { setFieldValue, values } = useFormik({
    initialValues: {
      step: 0,
      lastStep: data.length - 1,
      terms: data.map((item) => ({ ...item, checked: false })),
    },
  });

  const handleCheckbox = (index) => {
    setFieldValue(`terms[${index}].checked`, !values.terms[index].checked);
  };

  const handleNext = () => {
    if (values.step === values.lastStep) return;
    if (!values.terms[values.step].checked) return;
    setFieldValue('step', values.step + 1);
  };

  const handleBack = () => {
    if (values.step === 0) return;
    setFieldValue('step', values.step - 1);
  };

  const hasUncheckedTerm = Boolean(values.terms.find((term) => term.checked === false));

  return (
    <Modal style={{ width: window.innerWidth - 60, maxWidth: 670 }} header="Important Terms and Conditions" show={isOpen} onHide={onClose}>
      <div className="invoice-modal-body">
        <div style={{ height: 320, overflow: 'auto', marginBottom: 20 }}>
          <h4>{values.terms[values.step].name}</h4>
          <div dangerouslySetInnerHTML={{ __html: values.terms[values.step].text }} />
        </div>

        <CheckBox
          right={false}
          label="I have read and agree to the terms and conditions."
          className="justify-content-start"
          checked={values.terms[values.step].checked}
          onChange={() => handleCheckbox(values.step)}
        />
      </div>
      <div className="footer-buttons">
        <ModalButton title="Cancel" variant="cancel" onClick={onClose} />
        <ModalButton title="Back" variant="cancel" disabled={values.step === 0} onClick={handleBack} />
        {values.step === values.lastStep ? (
          <ModalButton title="Submit" disabled={hasUncheckedTerm} variant="confirm" onClick={onSubmit} />
        ) : (
          <ModalButton title="Next" variant="confirm" onClick={handleNext} />
        )}
      </div>
    </Modal>
  );
};
