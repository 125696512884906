/* eslint-disable jsx-a11y/iframe-has-title */
import React, { Fragment, useEffect, useState, useRef, useMemo } from 'react';
import history from 'utils/history';
import './index.scss';

const LongDistanceQuote = () => {
  const [containerHeight, setContainerHeight] = useState('100vh');

  const iframe = useRef(null);
  const containerRef = useRef(null);

  const queryParams = useMemo(() => {
    const url = new URL(window.location.href);

    return {
      jobId: url.searchParams.get('job_id'),
    };
  }, []);

  const quoteUrl = `${process.env.REACT_APP_API_URL}/api/documents/quote-ld/${queryParams.jobId}/view`;
  const paymentLink = `/customer/termsConditions?job_id=${queryParams.jobId}&document=quote_ld`;

  useEffect(() => {
    function handleResize() {
      const { innerHeight: windowHeight } = window;
      const contentHeight = containerRef?.current?.offsetHeight;

      if (windowHeight && contentHeight && windowHeight < contentHeight) {
        setContainerHeight(windowHeight + 'px');
      }
    }

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Fragment>
      <div className="ld-quote-container" style={{ height: containerHeight }} ref={containerRef}>
        <header>
          <h1>Long Distance Quote</h1>
        </header>

        <div className="ld-quote-iframe-wrap">
          <iframe ref={iframe} src={quoteUrl} id="ld_quote_data" name="ld_quote_data" className="ld-quote-iframe" />
        </div>

        <footer>
          <button type="button" className="btn" onClick={() => history.push(paymentLink)}>
            Pay
          </button>
        </footer>
      </div>
    </Fragment>
  );
};

export default LongDistanceQuote;
