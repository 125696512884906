const routes = {
  CLIENT: '/client',
  JOBS: '/client/jobs',
  SMS: '/client/sms',
  COMMUNICATIONS: '/client/communications',
  CALENDAR: '/client/calendar',
  REPORTS: '/client/reports',
  LOGS: '/client/logs',
  NOTES: '/client/notes',
  AVAILABLE: '/client/available',
  LOGIN: '/login',
  ANNUAL_CALENDAR: '/annual-calendar',
  CUSTOMER_EDIT_JOB: '/customer/edit-job/',
  REMINDERS: '/reminders/',
  PACKING: '/customer/packing/',
  INVENTORY: '/customer/inventory/',
  CREW_CALENDAR: '/crew-calendar/',
  TERMS: '/customer/termsConditions/',
  INVOICE: '/customer/invoice/',
  LD_CONTRACT: '/customer/ld_contract/',
  LD_QUOTE: '/customer/ld_quote/',
  STORAGE_CONTRACT: '/customer/storage/contract/',
  PAYMENT: '/customer/payment/',
  THANK_YOU: '/customer/info/:type?/:job_id?',
  COI_CERT: '/customer/coi/certificate/view/:job_id?/:type?',
  COI_CERT_APPROVING_SUCCESS: '/customer/info/coi',
  COI_CERT_APPROVING_REJECTED: '/customer/info/coi-rejected',
  ELEVATOR_RESERVATION: '/customer/elevator-reservation/:job_id?/:type?',
};

const routePaths = {
  JOBS: `${routes.JOBS}/:type?/:id?/:tab?`,
  SMS: routes.SMS,
  COMMUNICATIONS: `${routes.COMMUNICATIONS}/:type`,
  CALENDAR: `${routes.CALENDAR}/:date?`,
  REPORTS: `${routes.REPORTS}/:type?`,
  LOGS: routes.LOGS,
  NOTES: `${routes.NOTES}/:type?/:id?`,
};

export { routes as default, routePaths };
